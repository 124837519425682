// components/CookieConsent.tsx

import { useState, useEffect } from 'react';
import { Button, Group, Modal, Text } from '@mantine/core';
import { useRouter } from 'next/router';

const CookieConsent: React.FC<{}> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const router = useRouter();

  useEffect(() => {
    const consent = localStorage.getItem('allowCookie');
    if ((!consent || consent === 'rejected') && router.pathname !== '/cookie') {
      setIsVisible(true);
    }
  }, [router.pathname]);

  const handleAccept = () => {
    localStorage.setItem('allowCookie', 'accepted');
    setIsVisible(false);
  };

  const handleLeave = () => {
    localStorage.setItem('allowCookie', 'rejected');
    setIsVisible(false);
    router.push(`/cookie`);
  };

  if (!isVisible) return null;

  return (
    <Modal opened={isVisible} onClose={() => setIsVisible(false)} title="Cookie Consent" centered>
      <Text>
        We use cookies to enhance your experience and analyze site traffic. By using our website,
        you consent to our use of cookies.
      </Text>
      <Group position="right" mt="md">
        <Button onClick={handleLeave} color="red" ml="md">
          Decline All
        </Button>
        <Button onClick={handleAccept} color="gold">
          Accept All
        </Button>
      </Group>
    </Modal>
  );
};

export default CookieConsent;
