export enum UploadType {
  Image = 'image',
  Model = 'model',
  Default = 'default',
}

export type UploadTypeUnion = `${UploadType}`;

export enum OnboardingSteps {
  TOS = 1,
  Profile = 2,
  BrowsingLevels = 4,
  Amor = 8,
}

export enum ModelSort {
  Random = 'Random',
  MostLoved = 'Most Loved',
  MostDiscussed = 'Most Discussed',
  MostCollected = 'Most Collected',
  ImageCount = 'Most Selfies',
  HighestRated = 'Highest Rated',
  Newest = 'Newest',
  Oldest = 'Oldest',
}

export enum ReviewSort {
  Newest = 'newest',
  Oldest = 'oldest',
  MostLiked = 'most-liked',
  MostDisliked = 'most-disliked',
  MostComments = 'most-comments',
  Rating = 'rating',
}

export enum ReviewFilter {
  NSFW = 'nsfw',
  IncludesImages = 'includes-images',
}

export enum QuestionSort {
  Newest = 'Newest',
  MostLiked = 'Most Liked',
}

export enum QuestionStatus {
  Answered = 'Answered',
  Unanswered = 'Unanswered',
}

export enum ImageSort {
  Random = 'Random',
  MostLoved = 'Most Loved',
  MostReactions = 'Most Reactions',
  MostTipped = 'Most Amor',
  MostComments = 'Most Comments',
  MostCollected = 'Most Collected',
  Newest = 'Newest',
  Oldest = 'Oldest',
}

export enum ImageSortHidden {}

export enum PostSort {
  Random = 'Random',
  MostLoved = 'Most Loved',
  MostReactions = 'Most Reactions',
  MostComments = 'Most Comments',
  MostCollected = 'Most Collected',
  Newest = 'Newest',
  Oldest = 'Oldest',
}

export enum ImageResource {
  Manual = 'Manual',
  Automatic = 'Automatic',
}

export enum TagSort {
  MostModels = 'Most Models',
  MostImages = 'Most Images',
  MostPosts = 'Most Selfies',
  MostArticles = 'Most Articles',
}

export enum ImageScanType {
  Moderation,
  Label,
  FaceDetection,
  WD14,
}

export enum CommentV2Sort {
  Newest = 'Newest',
  Oldest = 'Oldest',
}

export enum ArticleSort {
  MostBookmarks = 'Most Bookmarks',
  MostReactions = 'Most Reactions',
  MostTipped = 'Most Amor',
  MostComments = 'Most Comments',
  MostCollected = 'Most Collected',
  Newest = 'Newest',
}

export enum CollectionSort {
  MostContributors = 'Most Followers',
  Newest = 'Newest',
}

export enum SignalMessages {
  AmorUpdate = 'amor:update',
  ChatNewMessage = 'chat:new-message',
  // ChatUpdateMessage = 'chat:update-message',
  ChatNewRoom = 'chat:new-room',
  // ChatMembershipChange = 'chat:membership-change',
  ChatTypingStatus = 'chat:typing-status',
}

export enum BountySort {
  EndingSoon = 'Ending Soon',
  HighestBounty = 'Highest Bounty',
  MostLiked = 'Most Liked',
  MostDiscussed = 'Most Discussed',
  MostContributors = 'Most Contributors',
  MostTracked = 'Most Tracked',
  MostEntries = 'Most Entries',
  Newest = 'Newest',
}

export enum BountyBenefactorSort {
  HighestAmount = 'Highest Amount',
  Newest = 'Newest',
}

export enum BountyStatus {
  Open = 'Open',
  Expired = 'Expired',
  Awarded = 'Awarded',
}

export enum CollectionReviewSort {
  Newest = 'Newest',
  Oldest = 'Oldest',
}

export enum ClubMembershipSort {
  MostRecent = 'MostRecent',
  NextBillingDate = 'NextBillingDate',
  MostExpensive = 'MostExpensive',
}

export enum ClubSort {
  Newest = 'Newest',
  MostResources = 'Most Resources',
  MostPosts = 'Most Club Selfies',
  MostMembers = 'Most Members',
}

export enum BlockedReason {
  TOS = 'tos',
  Moderated = 'moderated',
}

export enum ThreadSort {
  Newest = 'Newest',
  Oldest = 'Oldest',
  MostReactions = 'Most Reactions',
}

export enum SearchIndexUpdateQueueAction {
  Update = 'Update',
  Delete = 'Delete',
}

export enum PurchasableRewardModeratorViewMode {
  Available = 'Available',
  History = 'History',
  Purchased = 'Purchased',
}

export enum PurchasableRewardViewMode {
  Available = 'Available',
  Purchased = 'Purchased',
}
