import { Alert, Badge, BadgeProps, Button, Center, Stack, Text } from '@mantine/core';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import Router from 'next/router';
import React, { createContext, useCallback, useContext } from 'react';
import { create } from 'zustand';
import { ConfirmDialog } from '~/components/Dialog/Common/ConfirmDialog';
import { dialogStore } from '~/components/Dialog/dialogStore';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useImageStore } from '~/store/image.store';
import { trpc } from '~/utils/trpc';
import { showErrorNotification } from '~/utils/notifications';
import { useStore } from '~/components/ImageGuard/ImageGuard';

type ImageProps = {
  id: number;
  userId?: number;
  user?: { id: number };
  url?: string | null;
  purchased?: boolean;
  price: number | null;
};

type ConnectId = string | number;
type ConnectType =
  | 'model'
  | 'review'
  | 'user'
  | 'post'
  | 'collectionItem'
  | 'collection'
  | 'bounty'
  | 'bountyEntry'
  | 'club'
  | 'article';

export type ImageGuardConnect = { connectType: ConnectType; connectId: ConnectId };

type ConnectProps =
  | { connectType?: never; connectId?: never }
  | { connectType: ConnectType; connectId: ConnectId };

const useShowConnectionStore = create<Record<string, boolean>>(() => ({}));
export const useShowImagesStore = create<Record<number, boolean>>(() => ({}));

function getConnectionKey({
  connectId,
  connectType,
}: {
  connectType?: ConnectType;
  connectId?: ConnectId;
}) {
  if (!connectId || !connectType) return null;
  return `${connectId}_${connectType}`;
}

const ImageGuardCtx = createContext<{
  show: boolean;
  imageId: number;
  price: number | null;
  key: string | null;
  userId?: number;
} | null>(null);

function useImageGuardContext() {
  const context = useContext(ImageGuardCtx);
  if (!context) throw new Error('missing ImageGuardProvider');
  return context;
}

export function ImageGuard2({
  image,
  children,
  connectId,
  connectType,
  explain = true,
}: {
  image: ImageProps;
  children: (show: boolean) => React.ReactElement | null;
  explain?: boolean;
} & ConnectProps) {
  const currentUser = useCurrentUser();
  const showImage = useShowImagesStore(useCallback((state) => state[image.id], [image.id]));
  const key = getConnectionKey({ connectType, connectId });
  const { tosViolation } = useImageStore(image);

  const userId = image.userId ?? image.user?.id;

  const show =
    currentUser !== null &&
    (currentUser?.isMember ||
      currentUser?.id === userId ||
      showImage ||
      image.price === 0 ||
      !image.price ||
      (image?.purchased ?? false));

  return (
    <ImageGuardCtx.Provider
      value={{
        show,
        imageId: image.id,
        price: image.price,
        key,
        userId,
      }}
    >
      {!show && explain && (
        <BlurToggle>
          {(toggle) => (
            <Center
              style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              className="z-10 absolute z-20 transform -translate-x-1/2 -translate-y-[60%] top-1/2 left-1/2 flex flex-col text-white"
            >
              <Stack align="center" spacing="sm" w="100%">
                <Button
                  onClick={toggle}
                  radius="xl"
                  sx={(theme) => ({
                    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
                    backgroundColor: theme.fn.rgba(
                      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                      0.6
                    ),
                    boxShadow: theme.shadows.sm,
                    '&:hover': {
                      backgroundColor: theme.fn.rgba(
                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                        0.7
                      ),
                    },
                  })}
                >
                  Show
                </Button>
              </Stack>
            </Center>
          )}
        </BlurToggle>
      )}
      {tosViolation ? (
        <Center w="100%" h="100%">
          <Alert color="red">TOS Violation</Alert>
        </Center>
      ) : (
        children(show)
      )}
    </ImageGuardCtx.Provider>
  );
}

function BlurToggle({
  className,
  children,
  sfwClassName,
  nsfwClassName,
  ...badgeProps
}: Omit<BadgeProps, 'children'> & {
  children?: (toggle: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) => React.ReactElement;
  sfwClassName?: string;
  nsfwClassName?: string;
}) {
  const currentUser = useCurrentUser();
  const { show, imageId, price, key, userId } = useImageGuardContext();
  const toggleImage = useStore((state) => state.toggleImage);

  const { mutate } = trpc.image.purchaseImage.useMutation({
    async onSuccess(_, { id }) {
      toggleImage(imageId);
      useShowImagesStore.setState((state) => ({ [imageId]: true }));
    },
    onError(error: any) {
      showErrorNotification({ error: new Error(error.message) });
    },
  });

  const toggle = (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
    toggleShow({ event, isAuthed: !!currentUser, key, imageId, price, mutate });

  if (children) {
    return children(toggle);
  }

  return (
    <Badge component="button" className="cursor-pointer" {...badgeProps} onClick={toggle}>
      {show ? <IconEyeOff size={14} strokeWidth={2.5} /> : <IconEye size={14} strokeWidth={2.5} />}
    </Badge>
  );
}

function toggleShow({
  event,
  isAuthed,
  key,
  imageId,
  price,
  mutate,
}: {
  event: React.MouseEvent<HTMLElement, MouseEvent>;
  isAuthed: boolean;
  key: string | null;
  imageId: number;
  price: number | null;
  mutate: any;
}) {
  event.preventDefault();
  event.stopPropagation();

  // handle limited toggles for unauthenticated users
  if (!isAuthed) {
    Router.push(`/login?returnUrl=${Router.asPath}&reason=blur-toggle`);
    // dialogStore.trigger({
    //   id: 'limited-toggle-modal',
    //   component: ConfirmDialog,
    //   props: {
    //     message: (
    //       <Text size="sm" weight={500} sx={{ flex: 1 }}>
    //         Login now to continue viewing contents and unblur everything.
    //       </Text>
    //     ),
    //     onConfirm: () => Router.push(`/login?returnUrl=${Router.asPath}&reason=blur-toggle`),
    //     labels: { cancel: 'Cancel', confirm: 'Continue' },
    //   },
    // });
  }

  if (isAuthed) openPurchaseImageModel(imageId, price, mutate);
}

ImageGuard2.BlurToggle = BlurToggle;

export function openPurchaseImageModel(imageId: number, price: number | null, mutate: any) {
  dialogStore.trigger({
    id: 'purchase-selfie-toggle-modal',
    component: ConfirmDialog,
    props: {
      onConfirm: async () => {
        if (imageId && mutate) {
          await mutate({ id: imageId });
        }
      },
      message: (
        <Text size="sm" weight={500} sx={{ flex: 1 }}>
          An amount of {price ?? 0} will be withdrawn from your Amor account.
        </Text>
      ),
      labels: { cancel: 'Cancel', confirm: 'Continue' },
    },
  });
}
