import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  Group,
  HoverCard,
  MantineSize,
  Menu,
  MenuDividerProps,
  MenuItemProps,
  MenuLabelProps,
  Popover,
  Stack,
  Sx,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { CollectionType } from '@prisma/client';
import {
  IconAlertTriangle,
  IconCheck,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconFlag,
  IconLock,
  IconPencil,
  IconTrash,
  IconUser,
  IconUserMinus,
} from '@tabler/icons-react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import React, { cloneElement, createContext, useContext, useState } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { LoginRedirect } from '~/components/LoginRedirect/LoginRedirect';
import { AddToCollectionMenuItem } from '~/components/MenuItems/AddToCollectionMenuItem';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { openContext } from '~/providers/CustomModalsProvider';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { ReportEntity } from '~/server/schema/report.schema';
import { SimpleUser } from '~/server/selectors/user.selector';
import { trpc } from '~/utils/trpc';
import { isDefined } from '~/utils/type-guards';
import { AddToShowcaseMenuItem } from '~/components/Profile/AddToShowcaseMenuItem';
import { triggerRoutedDialog } from '~/components/Dialog/RoutedDialogProvider';
import { useImageStore } from '~/store/image.store';
import { ToggleSearchableMenuItem } from '../MenuItems/ToggleSearchableMenuItem';
import { showErrorNotification } from '~/utils/notifications';
import { openPurchaseImageModel, useShowImagesStore } from '~/components/ImageGuard/ImageGuard2';

export type ImageGuardConnect = {
  entityType:
    | 'model'
    | 'review'
    | 'user'
    | 'post'
    | 'collectionItem'
    | 'collection'
    | 'bounty'
    | 'bountyEntry'
    | 'club';
  entityId: string | number;
};

type SfwStore = {
  showingConnections: Record<string, boolean>;
  showingImages: Record<string, boolean>;
  toggleImage: (id: number) => void;
  showImages: (ids: number[]) => void;
  toggleConnection: ({ entityType, entityId }: ImageGuardConnect) => void;
};
const getConnectionKey = ({ entityId, entityType }: ImageGuardConnect) =>
  `${entityId}_${entityType}`;
export const useStore = create<SfwStore>()(
  immer((set) => ({
    showingConnections: {},
    showingImages: {},
    toggleImage: (id) => {
      set((state) => {
        state.showingImages[id.toString()] = !state.showingImages[id.toString()];
      });
    },
    showImages: (ids) => {
      set((state) => {
        ids.map((id) => (state.showingImages[id.toString()] = true));
      });
    },
    toggleConnection: (args) => {
      set((state) => {
        const key = getConnectionKey(args);
        state.showingConnections[key] = !state.showingConnections[key];
      });
    },
  }))
);
// #endregion

// #region [ImageGuardContext]
type ImageGuardState = {
  images: ImageProps[];
  connect?: ImageGuardConnect;
};
const ImageGuardCtx = createContext<ImageGuardState>({} as any);
const useImageGuardContext = () => {
  const context = useContext(ImageGuardCtx);
  if (!context) throw new Error('useImageGuardContext can only be used inside ImageGuardCtx');
  return context;
};
// #endregion

/**NOTES**
 - `connect` allows our images to be managed by a parent entity.
 - use case: home page, model card, toggle image - since I don't have all the images yet, I need to be able to still manage state for all the images without having the knowledge of which images are
 */

type ImageProps = {
  id: number;
  type: string;
  postId?: number | null;
  needsReview?: string | null;
  purchased?: boolean;
  userId?: number;
  categoryId?: number;
  user?: SimpleUser;
  url?: string | null;
  price: number | null;
};

type ImageGuardProps<T extends ImageProps> = {
  images: T[];
  connect?: ImageGuardConnect;
  render: (image: T, index: number) => React.ReactNode;
  children?: React.ReactNode;
  freeShow?: boolean;
};

export function ImageGuard<T extends ImageProps>({
  images: initialImages,
  connect,
  render,
  children,
  freeShow,
}: ImageGuardProps<T>) {
  const images = initialImages.filter(isDefined).filter((x) => x.id);

  return (
    <ImageGuardCtx.Provider value={{ images, connect }}>
      {images.map((image, index) => (
        <ImageGuardContentProvider key={image.id} image={image} freeShow={freeShow ?? false}>
          {render(image, index)}
        </ImageGuardContentProvider>
      ))}
      {children}
    </ImageGuardCtx.Provider>
  );
}

const ImageGuardContentCtx = createContext<{
  image: ImageProps;
  safe: boolean;
  showToggleImage: boolean;
  showToggleConnect: boolean;
  showReportNsfw: boolean;
  isOwner: boolean;
  isModerator: boolean;
} | null>(null);
const useImageGuardContentContext = () => {
  const context = useContext(ImageGuardContentCtx);
  if (!context)
    throw new Error('useImageGuardContentContext can only be used inside ImageGuardContentCtx');
  return context;
};

function ImageGuardContentProvider({
  children,
  image: initialImage,
  freeShow,
}: {
  children: React.ReactNode;
  image: ImageProps;
  freeShow: boolean;
}) {
  const image = useImageStore(initialImage);
  const { connect } = useImageGuardContext();
  const currentUser = useCurrentUser();

  const showImage = useStore((state) => state.showingImages[image.id.toString()] ?? false);
  const showConnection = useStore((state) =>
    connect ? state.showingConnections[getConnectionKey(connect)] : undefined
  );

  const userId: number | undefined = (image as any).userId ?? (image as any).user?.id;
  const isOwner = userId === currentUser?.id;
  const isModerator = currentUser?.isModerator ?? false;
  let safe =
    (showConnection ?? showImage) ||
    (currentUser !== null &&
      (currentUser?.isMember ||
        isOwner ||
        image.price === 0 ||
        !image.price ||
        (image?.purchased ?? false)));
  if (freeShow) safe = false;

  const showToggleImage = !connect;
  const showToggleConnect = !!connect;
  const showReportNsfw = !!currentUser;

  return (
    <ImageGuardContentCtx.Provider
      value={{
        safe,
        showToggleImage,
        showToggleConnect,
        showReportNsfw,
        image,
        isOwner,
        isModerator,
      }}
    >
      {image.tosViolation ? (
        <Center w="100%" h="100%">
          <Alert color="red">TOS Violation</Alert>
        </Center>
      ) : (
        children
      )}
    </ImageGuardContentCtx.Provider>
  );
}

ImageGuard.Unsafe = function Unsafe({ children }: { children: React.ReactNode }) {
  const { safe } = useImageGuardContentContext();
  return !safe ? <>{children}</> : null;
};

ImageGuard.Safe = function Safe({ children }: { children?: React.ReactNode }) {
  const { safe } = useImageGuardContentContext();
  return safe ? <>{children}</> : null;
};

// #region [ImageGuardReportContext]
type ImageGuardReportState = {
  getMenuItems: (
    data: ImageProps & { menuItems: Array<{ key: string; component: React.ReactElement }> }
  ) => React.ReactElement[];
};

export const ImageGuardReportContext = createContext<ImageGuardReportState>({
  getMenuItems: ({ menuItems }) => menuItems.map((item) => item.component),
});

const useImageGuardReportContext = () => {
  const context = useContext(ImageGuardReportContext);
  if (!context) {
    return {
      getMenuItems: ({ menuItems }) => menuItems.map((item) => item.component),
    } as ImageGuardReportState;
  }

  return context;
};
// #endregion

ImageGuard.Report = function ReportImage({
  position = 'top-right',
  withinPortal = false,
  context = 'image',
  additionalMenuItems,
}: {
  position?: 'static' | 'top-left' | 'top-right';
  withinPortal?: boolean;
  context?: 'post' | 'image';
  additionalMenuItems?:
    | React.ReactElement<MenuItemProps | MenuDividerProps | MenuLabelProps>[]
    | null;
}) {
  const utils = trpc.useContext();
  const { getMenuItems } = useImageGuardReportContext();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const features = useFeatureFlags();
  const { image, showReportNsfw, isOwner, isModerator } = useImageGuardContentContext();
  const [needsReview, setNeedsReview] = useState(image.needsReview);

  const moderateImagesMutation = trpc.image.moderate.useMutation();
  const handleModerate = async (
    e: React.SyntheticEvent,
    action: 'accept' | 'delete' | 'removeName'
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isModerator) return;
    moderateImagesMutation.mutate({
      ids: [image.id],
      needsReview: action === 'accept' ? null : undefined,
      reviewAction: action !== 'accept' ? action : undefined,
      reviewType: 'minor',
    });
    setNeedsReview(null);
  };

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openContext('report', { entityType: ReportEntity.Image, entityId: image.id }, { zIndex: 1000 });
  };

  const handleEditClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    Router.push(`/selfie_posts/${image.postId}/edit?categoryId=${image.categoryId}`);
  };

  let NeedsReviewBadge = needsReview && (
    <ThemeIcon size="lg" color={needsReview === 'csam' && isModerator ? 'red' : 'yellow'}>
      {needsReview === 'poi' ? (
        <IconUser strokeWidth={2.5} size={26} />
      ) : (
        <IconAlertTriangle strokeWidth={2.5} size={26} />
      )}
    </ThemeIcon>
  );

  if (needsReview && needsReview !== 'csam' && isModerator)
    NeedsReviewBadge = (
      <Menu position="bottom">
        <Menu.Target>
          <Box
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {NeedsReviewBadge}
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={(e) => handleModerate(e, 'accept')}
            icon={<IconCheck size={14} stroke={1.5} />}
          >
            Approve
          </Menu.Item>
          {needsReview === 'poi' && (
            <Menu.Item
              onClick={(e) => handleModerate(e, 'removeName')}
              icon={<IconUserMinus size={14} stroke={1.5} />}
            >
              Remove Name
            </Menu.Item>
          )}
          <Menu.Item
            onClick={(e) => handleModerate(e, 'delete')}
            icon={<IconTrash size={14} stroke={1.5} />}
          >
            Reject
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  else if (needsReview && !isModerator) {
    NeedsReviewBadge = (
      <HoverCard width={200} withArrow>
        <HoverCard.Target>{NeedsReviewBadge}</HoverCard.Target>
        <HoverCard.Dropdown p={8}>
          <Stack spacing={0}>
            <Text weight="bold" size="xs">
              Flagged for review
            </Text>
            <Text size="xs">
              {`This image won't be visible to other users until it's reviewed by our moderators.`}
            </Text>
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }

  const defaultMenuItems: Array<{ key: string; component: React.ReactElement }> = [];
  if (features.collections) {
    defaultMenuItems.push({
      key: 'add-to-collection',
      component: (
        <AddToCollectionMenuItem
          key="add-to-collection"
          onClick={() => {
            switch (context) {
              case 'post':
                if (!image.postId) {
                  return;
                }
                openContext('addToCollection', { postId: image.postId, type: CollectionType.Post });
                break;
              default: {
                openContext('addToCollection', { imageId: image.id, type: CollectionType.Image });
                break;
              }
            }
          }}
        />
      ),
    });
  }

  if (image.postId) {
    defaultMenuItems.push({
      key: 'toggle-searchable-menu-item',
      component: (
        <ToggleSearchableMenuItem
          entityType="Post"
          entityId={image.postId}
          key="toggle-searchable-menu-item"
        />
      ),
    });
  }

  if (!isOwner)
    defaultMenuItems.push({
      key: 'report',
      component: (
        <LoginRedirect reason="report-content" key="report">
          <Menu.Item icon={<IconFlag size={14} stroke={1.5} />} onClick={handleClick}>
            Report selfie
          </Menu.Item>
        </LoginRedirect>
      ),
    });

  if (currentUser && (isOwner || isModerator) && image.postId)
    defaultMenuItems.push({
      key: 'edit-post',
      component: (
        <Menu.Item
          icon={<IconPencil size={14} stroke={1.5} />}
          onClick={handleEditClick}
          key="edit-post"
        >
          Edit
        </Menu.Item>
      ),
    });

  const postId = image.postId;
  if (postId && !router.query.postId)
    defaultMenuItems.push({
      key: 'view-post',
      component: (
        <Menu.Item
          key="view-post"
          icon={<IconEye size={14} stroke={1.5} />}
          onClick={(e) => {
            e.stopPropagation();
            triggerRoutedDialog({ name: 'postDetail', state: { postId: postId } });
          }}
        >
          View selfie post
        </Menu.Item>
      ),
    });

  if (!postId && isModerator)
    defaultMenuItems.push({
      key: 'view-image-detail',
      component: (
        <Menu.Item
          key="view-image-detail"
          icon={<IconEye size={14} stroke={1.5} />}
          onClick={(e) => {
            e.stopPropagation();
            triggerRoutedDialog({
              name: 'imageDetail',
              state: {
                type: image.type,
                imageId: image.id,
              },
            });
          }}
        >
          View image detail
        </Menu.Item>
      ),
    });

  if (isOwner && context === 'image') {
    defaultMenuItems.push({
      key: 'add-image-to-showcase',
      component: (
        <AddToShowcaseMenuItem key="add-image-to-showcase" entityType="Image" entityId={image.id} />
      ),
    });
  }

  const userId = image.userId ?? image.user?.id;

  const menuItems = getMenuItems({ ...image, menuItems: defaultMenuItems });

  if (!menuItems) return null;

  return (
    <Group
      spacing={4}
      style={
        position !== 'static'
          ? {
              position: 'absolute',
              top: 5,
              left: position === 'top-left' ? 5 : undefined,
              right: position === 'top-right' ? 5 : undefined,
              zIndex: 8,
            }
          : undefined
      }
    >
      {NeedsReviewBadge}
      {!!menuItems.length && (
        <Menu position="left-start" offset={-5} withinPortal={withinPortal} withArrow>
          <Menu.Target>
            <ActionIcon
              variant="transparent"
              p={0}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              sx={{ width: 30 }}
            >
              <IconDotsVertical
                size={26}
                color="#fff"
                filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 50%)) drop-shadow(0px 5px 15px rgb(0 0 0 / 60%))"
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {menuItems}
            {additionalMenuItems}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
};

const NsfwBadge = ({
  showImage,
  position,
  sx,
  className,
  onClick,
  size = 'sm',
}: {
  showImage: boolean;
  onClick: () => void;
  position?: 'static' | 'top-left' | 'top-right';
  sx?: Sx;
  className?: string;
  size?: MantineSize;
}) => {
  return (
    <ImageGuardPopover>
      <Center
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        className="z-10 absolute z-20 transform -translate-x-1/2 -translate-y-[60%] top-1/2 left-1/2 flex flex-col text-white"
      >
        {!showImage && (
          <Stack align="center" spacing="sm" w="100%">
            <Button
              onClick={onClick}
              radius="xl"
              sx={(theme) => ({
                color: theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[9],
                backgroundColor: theme.fn.rgba(
                  theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                  0.6
                ),
                boxShadow: theme.shadows.sm,
                '&:hover': {
                  backgroundColor: theme.fn.rgba(
                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    0.7
                  ),
                },
              })}
            >
              Show
            </Button>
          </Stack>
        )}
      </Center>
    </ImageGuardPopover>
  );
};

ImageGuard.ToggleImage = function ToggleImage(props: {
  position?: 'static' | 'top-left' | 'top-right';
  sx?: Sx;
  className?: string;
  size?: MantineSize;
  variant?: 'badge' | 'button';
}) {
  const { image, showToggleImage } = useImageGuardContentContext();
  const showImage = useStore((state) => state.showingImages[image.id.toString()]);
  const toggleImage = useStore((state) => state.toggleImage);
  const currentUser = useCurrentUser();

  const userId = image.userId ?? image.user?.id;
  const show =
    currentUser !== null &&
    (currentUser?.isMember ||
      currentUser?.id === userId ||
      showImage ||
      image.price === 0 ||
      !image.price ||
      (image?.purchased ?? false));

  const { mutate } = trpc.image.purchaseImage.useMutation({
    async onSuccess(_, { id }) {
      toggleImage(image.id);
      useShowImagesStore.setState((state) => ({ [image.id]: true }));
    },
    onError(error: any) {
      showErrorNotification({ error: new Error(error.message) });
    },
  });

  if (!showToggleImage) return null;

  return (
    <NsfwBadge
      showImage={show}
      onClick={() => !!currentUser && openPurchaseImageModel(image.id, image.price, mutate)}
      {...props}
    />
  );
};

ImageGuard.ToggleConnect = function ToggleConnect(props: {
  position?: 'static' | 'top-left' | 'top-right';
  sx?: Sx;
  className?: string;
  size?: MantineSize;
}) {
  const { connect } = useImageGuardContext();
  const { image, showToggleConnect } = useImageGuardContentContext();
  const showImage = useStore((state) => state.showingImages[image?.id.toString()] ?? false);
  const showConnect = useStore((state) =>
    connect ? state.showingConnections[getConnectionKey(connect)] : false
  );
  const toggleConnect = useStore((state) => state.toggleConnection);
  const currentUser = useCurrentUser();

  // if (!connect || (!image && !showToModerator)) return null;
  if (!showToggleConnect) return null;

  const userId = image.userId ?? image.user?.id;

  const showing =
    (showConnect ?? showImage) ||
    (currentUser !== null &&
      (currentUser?.isMember ||
        currentUser?.id === userId ||
        image.price === 0 ||
        !image.price ||
        (image?.purchased ?? false)));

  const { mutate } = trpc.image.purchaseImage.useMutation({
    async onSuccess(_, { id }) {
      connect ? toggleConnect(connect) : undefined;
      useShowImagesStore.setState((state) => ({ [image.id]: true }));
    },
    onError(error: any) {
      showErrorNotification({ error: new Error(error.message) });
    },
  });

  return !showImage ? (
    <NsfwBadge
      showImage={showing}
      onClick={() => !!currentUser && openPurchaseImageModel(image.id, image.price, mutate)}
      {...props}
    />
  ) : (
    <></>
  );
};

ImageGuard.ToggleImageButton = function ToggleImageButton(props: {
  position?: 'static' | 'top-left' | 'top-right';
  sx?: Sx;
  className?: string;
  size?: MantineSize;
}) {
  const { position, size, sx, className } = props;
  const { image, showToggleImage, isOwner } = useImageGuardContentContext();
  const showImage = useStore((state) => state.showingImages[image.id.toString()]);
  const toggleImage = useStore((state) => state.toggleImage);

  if (!showToggleImage || showImage || isOwner) return null;

  return (
    <ImageGuardPopover>
      <ActionIcon
        color="gold"
        radius="xl"
        size={size}
        sx={(theme) => ({
          backgroundColor: theme.fn.rgba(theme.colors['gold'][9], 0.6),
          color: 'white',
          backdropFilter: 'blur(7px)',
          boxShadow: '1px 2px 3px -1px rgba(37,38,43,0.2)',
          ...(position !== 'static'
            ? {
                position: 'absolute',
                top: theme.spacing.xs,
                left: position === 'top-left' ? theme.spacing.xs : undefined,
                right: position === 'top-right' ? theme.spacing.xs : undefined,
                zIndex: 10,
              }
            : {
                zIndex: 10,
              }),
          ...(sx && sx instanceof Function ? sx(theme) : sx),
        })}
        className={className}
        onClick={() => toggleImage(image.id)}
      >
        {showImage ? (
          <IconEyeOff size={14} strokeWidth={2.5} />
        ) : (
          <IconEye size={14} strokeWidth={2.5} />
        )}
      </ActionIcon>
    </ImageGuardPopover>
  );
};

ImageGuard.GroupToggleConnect = function GroupToggleConnect(props: {
  position?: 'static' | 'top-left' | 'top-right';
  sx?: Sx;
  className?: string;
  size?: MantineSize;
}) {
  const currentUser = useCurrentUser();
  const { connect, images } = useImageGuardContext();
  const showConnect = useStore((state) =>
    connect ? state.showingConnections[getConnectionKey(connect)] : false
  );
  const toggleConnect = useStore((state) => state.toggleConnection);
  const showToggleConnect = !!connect;

  if (!showToggleConnect) return null;

  const showing = showConnect;

  return (
    <NsfwBadge
      showImage={showing}
      onClick={() => (connect ? toggleConnect(connect) : undefined)}
      {...props}
    />
  );
};

function ImageGuardPopover({ children }: { children: React.ReactElement }) {
  const user = useCurrentUser();
  const isAuthenticated = !!user;
  const [opened, setOpened] = useState(false);
  const router = useRouter();

  if (!isAuthenticated)
    return (
      <Popover
        width={300}
        position="bottom"
        opened={opened}
        withArrow
        closeOnClickOutside
        withinPortal
      >
        <Popover.Target>
          {cloneElement(children, {
            onClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              setOpened((o) => !o);
            },
          })}
        </Popover.Target>
        <Popover.Dropdown>
          <Stack spacing="xs">
            <Group>
              <ThemeIcon color="red" size="xl" variant="outline">
                <IconLock />
              </ThemeIcon>
              <Text size="sm" weight={500} sx={{ flex: 1 }}>
                Login now to continue viewing contents and unblur everything.
              </Text>
            </Group>

            <Link href={`/login?returnUrl=${router.asPath}&reason=blur-toggle`}>
              <Button size="xs">Login</Button>
            </Link>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    );

  return cloneElement(children, {
    onClick: (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      children.props.onClick?.();
    },
  });
}

ImageGuard.Content = function ImageGuardContent({
  children,
}: {
  children: ({ safe }: { safe: boolean }) => React.ReactElement;
}) {
  const { safe } = useImageGuardContentContext();
  return children({ safe });
};
